import React from 'react';
import { Box, ListItemIcon, MenuItem, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import CustomMaterialMenu from '../../dataTable/rowMenu/CustomMaterialMenu';
import ConfirmCancelModal from '../../shared/modal/ConfirmCancelModal';
import { MachineDeploymentContext } from '../contexts/DeploymentsContext';

interface DeploymentRowMenuProps {
  deployment: any;
}

const useStyles = makeStyles(() => ({
  listItemIcon: {
    minWidth: '24px',
  },
}));

const DeploymentRowMenu: React.FC<DeploymentRowMenuProps> = (props: DeploymentRowMenuProps) => {
  const { deployment } = props;
  const classes = useStyles();
  const [modalOpen, setModalOpen] = React.useState<boolean>(false);

  const { deleteMachineDelpoyment, fetchMachineDeployments } = React.useContext(MachineDeploymentContext);

  const handleEmail = async () => {
    deleteMachineDelpoyment((deployment.machine_software_deployment_id), () => fetchMachineDeployments())
    setModalOpen(false)
  };

  return (
    <Box>
      <ConfirmCancelModal
        isOpen={modalOpen}
        onCancel={() => setModalOpen(false)}
        onConfirm={handleEmail}
        closeOnConfirm
        title="Cancel Deployment?"
        hasFooterBar
        confirmLabel="Cancel Deployment"
      >
        <div style={{ margin: '10px 20px' }}>
          <Typography style={{ marginLeft: -15 }} variant="h2" component="h2">
            Are you sure you want to cancel this deploymet?
          </Typography>
          <Typography style={{ marginLeft: -15, marginTop: 5 }} variant="h4" component="h4">
            Please note that the deployment may still run before it can be deleted
          </Typography>
        </div>
      </ConfirmCancelModal>
      <CustomMaterialMenu size="medium">
        <div />
        <MenuItem onClick={() => setModalOpen(true)}>
          <ListItemIcon className={classes.listItemIcon}>
            <DeleteOutlinedIcon fontSize="small" color="secondary" />
          </ListItemIcon>
          <Typography variant="inherit">Cancel Deployment</Typography>
        </MenuItem>
      </CustomMaterialMenu>
    </Box>
  );
};

export default DeploymentRowMenu;

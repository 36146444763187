import { Button } from '@mui/material';
import { IDataTableColumn } from '../../dataTable/DataTable/model';
import { format, parseISO } from 'date-fns';
import TransactionRowMenu from '../components/DeploymentRowMenu';

const deploymentColumns: IDataTableColumn<any>[] = [
  {
    name: 'Deployment Id',
    selector: 'machine_software_deployment_id',
    sortable: true,
    grow: 0.5,
  },
  {
    name: 'Machine Name',
    selector: 'machine_name_with_serial',
    sortable: true,
    grow: 1.5,
  },
  {
    name: 'Company Name',
    selector: 'company_name',
    sortable: true,
    grow: 1.2,
  },
  {
    name: 'Created By User',
    selector: 'created_by_user',
    sortable: true,
    grow: 1.2,
  },
  {
    name: `Last Online (${new Date().toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ')[2]})`,
    selector: 'last_online_date_time_utc',
    sortable: true,
    grow: 1.2,
    cell: (row: Record<string, string>): JSX.Element => {
      let formattedDate = '';

      if (row.last_online_date_time_utc) {
        try {
          const date = parseISO(row.last_online_date_time_utc);

          formattedDate = format(date, 'MM/dd/yyyy hh:mm aaa');
        } catch (error) {
          console.error(`Error parsing date: ${error}`);
        }
      }
      return <p>{formattedDate}</p>;
    },
  },
  {
    name: 'System Version',
    selector: 'system_version',
    sortable: true,
  },
  {
    name: 'Deployed Version',
    selector: 'machine_software_version_name',
    sortable: true,
  },
  {
    name: 'Software Type',
    selector: 'machine_software_type',
    sortable: true,
  },
  {
    name: `Sent To Machine (${new Date().toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ')[2]})`,
    selector: 'scheduled_on_machine_date_time',
    sortable: true,
    cell: (row: Record<string, string>): JSX.Element => {
      let formattedDate = '';
      if (row.scheduled_on_machine_date_time) {
        try {
          const date = parseISO(row.scheduled_on_machine_date_time);

          formattedDate = format(date, 'MM/dd/yyyy hh:mm aaa');
        } catch (error) {
          console.error(`Error parsing date: ${error}`);
        }
      }
      return <p>{formattedDate}</p>;
    },
  },
  {
    name: `Completed time (${new Date().toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ')[2]})`,
    selector: 'completed_date_time',
    sortable: true,
    cell: (row: Record<string, string>): JSX.Element => {
      let formattedDate = '';
      if (row.completed_date_time) {
        try {
          const date = parseISO(row.completed_date_time);

          formattedDate = format(date, 'MM/dd/yyyy hh:mm aaa');
        } catch (error) {
          console.error(`Error parsing date: ${error}`);
        }
      }
      return <p>{formattedDate}</p>;
    },
  },
  {
    cell: (row) => <TransactionRowMenu deployment={row} />,
    name: 'Actions',
    allowOverflow: true,
    button: true,
    selector: 'actions',
  },
];

export default deploymentColumns;

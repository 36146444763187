import React from 'react';
import { ProductAutoBulkFormValues } from '../model';

interface ProductsAutoBulkValidationsContextInterface {
  validatecsvImportRow?: (formValues: ProductAutoBulkFormValues) => { error: boolean; errorMessage?: string };
  validateProductsAutoBulkFields?: (formValues: ProductAutoBulkFormValues) => boolean;
  formErrors?: Record<string, string>;
}

const ProductsAutoBulkValidationsContext = React.createContext<ProductsAutoBulkValidationsContextInterface>({});

const ProductsAutoBulkValidationsContextConsumer = ProductsAutoBulkValidationsContext.Consumer;
const ProductsAutoBulkValidationsContextProvider: React.FC = ({ children }) => {
  const [formErrors, setFormErrors] = React.useState({});

  const fetchValidCharactersError = (formValues: ProductAutoBulkFormValues): string => {
    const SpecialCharacterStringCheck =
      formValues?.product_id +
      formValues?.product_name +
      formValues?.product_description +
      formValues?.product_type_id +
      formValues?.product_type +
      formValues?.product_priority +
      formValues?.product_shipment +
      formValues?.product_inventory +
      formValues?.amount_type_id +
      formValues?.product_inventory_unit +
      formValues?.product_density +
      formValues?.product_density_type +
      formValues?.product_epa +
      formValues?.product_msds +
      formValues?.product_comment +
      formValues?.isolated +
      formValues?.source_name +
      formValues?.source_id +
      formValues?.user_name +
      formValues?.active +
      formValues?.date_and_time;

    const specialChars = /'|\/\*|\*\//;

    if (specialChars.test(SpecialCharacterStringCheck)) {
      return `No fields can contain  (')`;
    }
    return null;
  };

  const fetchProductNameError = (product_name: string) => {
    if (product_name === '') {
      return 'Product Name cannot be left blank.';
    }

    if (product_name?.length > 250) {
      return 'Product Name cannot be more than 250 characters';
    }
    return false;
  };

  const fetchProductDescriptionError = (product_description: string) => {
    if (product_description?.length > 100) {
      return 'Product Description cannot be more than 100 characters';
    }
    return false;
  };

  const fetchProductTypeError = (product_type) => {
    if (!product_type) {
      return false;
    }

    if (product_type.toLowerCase() !== 'liquid' && product_type.toLowerCase() !== 'dry') {
      return "Product Type must be 'liquid' or 'dry'";
    }

    return false;
  };

  const fetchProductInventoryUnitError = (product_inventory_unit: string, product_type: string) => {
    if (!product_inventory_unit) {
      return false;
    }

    if (product_type.toLowerCase() === 'dry') {
      if (product_inventory_unit.toLowerCase() !== 'lb') {
        return 'Dry products cannot be in gallons';
      }
    }

    if (product_inventory_unit) {
      if (product_inventory_unit.toLowerCase() !== 'gallon' && product_inventory_unit.toLowerCase() !== 'lb') {
        return "Product Inventory Unit must be 'gallon' or 'lb'";
      }
    }

    return false;
  };

  const fetchProductInventoryError = (product_inventory: string) => {
    if (!product_inventory) {
      return false;
    }

    if (/[a-zA-Z]/.test(product_inventory)) {
      return 'Product Inventory cannot contain letters';
    }
    return false;
  };

  // TODO @andy this validation show allow for lb for dry products, or allow null for desnity, need to look on-prem.
  const fetchDensityTypeError = (product_density_type: string, product_type: string) => {
    if (!product_density_type) {
      return false;
    }

    if (product_type === 'Liquid') {
      if (product_density_type.toLowerCase() !== 'lb/gal' && product_density_type.toLowerCase() !== 'mm/l') {
        return 'Invalid density type';
      }
    }
    return false;
  };

  const fetchDensityError = (product_density: string, product_type: string) => {
    if (!product_density) {
      return false;
    }

    if (product_density === '' && product_density !== undefined) {
      return 'Density cannot be left blank';
    }
    if (/[a-zA-Z]/.test(product_density)) {
      return 'Density cannot contain letters';
    }

    if (product_type.toLowerCase() === 'dry') {
      if (parseFloat(product_density) < 1 || parseFloat(product_density) > 14) {
        return 'Density must be between 1 and 14';
      }
    }

    if (parseFloat(product_density) < 5 || parseFloat(product_density) > 14) {
      return 'Density must be between 5 and 14';
    }

    return false;
  };

  const fetchPriorityError = (product_priority: string) => {
    if (!product_priority) {
      return false;
    }

    if (/[a-zA-Z]/.test(product_priority)) {
      return 'Product Priority cannot contain letters';
    }
    if (parseFloat(product_priority) < 0 || parseFloat(product_priority) > 999) {
      return 'Product Priority must be between 0 and 999';
    }
    return false;
  };

  const fetchIsolatedError = (isolated: string) => {
    if (!isolated) {
      return false;
    }

    if (typeof isolated === 'undefined') {
      return false;
    }
    if (
      isolated !== '1' &&
      isolated !== '0' &&
      isolated.toLowerCase() !== 'true' &&
      isolated.toLowerCase() !== 'false' &&
      isolated !== '' &&
      isolated !== null
    ) {
      return 'isolated must be 0 or 1';
    }
    return false;
  };

  const fetchIsolatedLoadLastError = (isolated_load_last: string) => {
    if (!isolated_load_last) {
      return false;
    }

    if (typeof isolated_load_last === 'undefined') {
      return false;
    }
    if (
      isolated_load_last !== '1' &&
      isolated_load_last !== '0' &&
      isolated_load_last.toLowerCase() !== 'true' &&
      isolated_load_last.toLowerCase() !== 'false' &&
      isolated_load_last !== '' &&
      isolated_load_last !== null
    ) {
      return 'Isolated Load Last must be 0 or 1';
    }
    return false;
  };

  const fetchActiveError = (active: string) => {
    if (!active) {
      return false;
    }

    if (typeof active === 'undefined') {
      return false;
    }
    if (
      active !== '1' &&
      active !== '0' &&
      active.toLowerCase() !== 'true' &&
      active.toLowerCase() !== 'false' &&
      active !== '' &&
      active !== null
    ) {
      return 'Active must be 0 or 1';
    }
    return false;
  };

  const validateProductsAutoBulkFields = (formValues: ProductAutoBulkFormValues) => {
    const productNameError = fetchProductNameError(formValues?.product_name);
    const specialCharactersError = fetchValidCharactersError(formValues);
    const densityError = fetchDensityError(formValues?.product_density, formValues?.product_type);
    const productDescriptionError = fetchProductDescriptionError(formValues?.product_description);
    const productInventoryUnitError = fetchProductInventoryUnitError(
      formValues?.product_inventory_unit,
      formValues?.product_type,
    );

    if (
      specialCharactersError ||
      productNameError ||
      densityError ||
      productDescriptionError ||
      productInventoryUnitError
    ) {
      setFormErrors({
        productName: productNameError,
        productInventoryUnitError,
        Density: densityError,
        base: specialCharactersError,
        Description: productDescriptionError,
      });

      return false;
    }

    setFormErrors({
      productName: null,
      productInventoryUnitError: null,
      Density: null,
      base: null,
      Description: null,
    });

    return true;
  };

  const validatecsvImportRow = (formValues: ProductAutoBulkFormValues): { error: boolean; errorMessage?: string } => {
    const specialCharactersError = fetchValidCharactersError(formValues);
    const productNameError = fetchProductNameError(formValues?.product_name);
    const productDescriptionError = fetchProductDescriptionError(formValues?.product_description);
    const productTypeError = fetchProductTypeError(formValues?.product_type);
    const productInventoryUnitError = fetchProductInventoryUnitError(
      formValues?.product_inventory_unit,
      formValues?.product_type,
    );
    const productInventoryError = fetchProductInventoryError(formValues?.product_inventory);
    const productDensityTypeError = fetchDensityTypeError(formValues?.product_density_type, formValues?.product_type);
    const productDensityError = fetchDensityError(formValues?.product_density, formValues?.product_type);
    const productPriorityError = fetchPriorityError(formValues?.product_priority);
    const productIsolatedError = fetchIsolatedError(formValues?.isolated);
    const productIsolatedLoadLastError = fetchIsolatedLoadLastError(formValues?.isolated_load_last);
    const productActiveError = fetchActiveError(formValues?.active);

    const errors = [
      specialCharactersError,
      productNameError,
      productDescriptionError,
      productTypeError,
      productInventoryUnitError,
      productInventoryError,
      productDensityTypeError,
      productDensityError,
      productPriorityError,
      productIsolatedError,
      productIsolatedLoadLastError,
      productActiveError,
    ].filter(Boolean);

    if (errors.length > 0) {
      return {
        error: true,
        errorMessage: errors.join(' | '),
      };
    }

    return { error: false };
  };

  return (
    <ProductsAutoBulkValidationsContext.Provider
      value={{
        validatecsvImportRow,
        validateProductsAutoBulkFields,
        formErrors,
      }}
    >
      {children}
    </ProductsAutoBulkValidationsContext.Provider>
  );
};

export {
  ProductsAutoBulkValidationsContextProvider,
  ProductsAutoBulkValidationsContextConsumer,
  ProductsAutoBulkValidationsContext,
};

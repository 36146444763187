import React from 'react';
import { Grid, Button, Typography } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AutocompleteFilter from '../../shared/form/AutocompleteFilter';
import FormInput from '../../shared/form/FormInput';
import SelectInput from '../../shared/form/SelectInput';
import defaultMachineFilterOptions from '../constants/defaultMachineFilterOptions';

interface DeploymentMachinesListFilterBarProps {
  machineTableFilters?: any;
  setMachineTableFilters?: any;
  filterOptions?: any;
  fetchFunction?: (filter: string) => void;
}

const DeploymentMachinesListFilterBar: React.FC<DeploymentMachinesListFilterBarProps> = (props) => {
  const { machineTableFilters, setMachineTableFilters, filterOptions, fetchFunction } = props;

  const handleApplyFilter = () => {
    // have to strip new line chars or else the http request fails
    const sanitizedFilter = machineTableFilters.advanced_filter.replace(/\r?\n|\r/g, ' ');
    fetchFunction(`advanced_filter=${sanitizedFilter}`);
  };

  return (
    <Grid container spacing={1} alignItems="flex-start">
      <Grid item xs={12} style={{ marginTop: 20 }}>
        <Grid container spacing={1} alignItems="flex-start">
          <Grid item md={2} xs={6}>
            <FormInput
              value={machineTableFilters?.machine_serial}
              onChange={(value) => setMachineTableFilters({ ...machineTableFilters, machine_serial: value })}
              label="Machine Serial"
            />
          </Grid>
          <Grid item md={2} xs={6}>
            <FormInput
              value={machineTableFilters?.machine_name}
              onChange={(value) => setMachineTableFilters({ ...machineTableFilters, machine_name: value })}
              label="Machine Name"
            />
          </Grid>
          <Grid item md={2} xs={6}>
            <FormInput
              value={machineTableFilters?.company}
              onChange={(value) => setMachineTableFilters({ ...machineTableFilters, company: value })}
              label="Company"
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <SelectInput
              value={machineTableFilters.system_type}
              choices={[
                { label: 'AutoTreat', value: 'AutoTreat' },
                { label: 'AutoBulk', value: 'AutoBulk' },
              ]}
              onChange={(value) => setMachineTableFilters({ ...machineTableFilters, system_type: value })}
              label="System Type"
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <SelectInput
              value={machineTableFilters?.connection_state}
              onChange={(value) => setMachineTableFilters({ ...machineTableFilters, connection_state: value })}
              label="Online Status"
              defaultChoice={{ label: 'All', value: 'All' }}
              choices={[
                { label: 'Offline', value: 'Offline' },
                { label: 'Online', value: 'Online' },
              ]}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <SelectInput
              value={machineTableFilters?.subscription_active}
              onChange={(value) => setMachineTableFilters({ ...machineTableFilters, subscription_active: value })}
              label="Subscription Status"
              defaultChoice={{ label: 'All', value: 'All' }}
              choices={[
                { label: 'Active', value: 'true' },
                { label: 'Inactive', value: 'false' },
              ]}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <SelectInput
              value={machineTableFilters?.last_online}
              onChange={(value) => setMachineTableFilters({ ...machineTableFilters, last_online: value })}
              label="Last Online"
              defaultChoice={{ label: 'All', value: 'All' }}
              choices={[
                { label: 'Last 10 minutes', value: '10' },
                { label: 'Last hour', value: '60' },
                { label: 'Last 24 hours', value: '1440' },
              ]}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <AutocompleteFilter
              label="Current Indusoft Version"
              loading={false}
              pluralLabel="Current Indusoft Versions"
              choices={filterOptions?.indusoftVersions}
              setValue={(value) => setMachineTableFilters({ ...machineTableFilters, current_indusoft_versions: value })}
              value={machineTableFilters.current_indusoft_versions}
            />
          </Grid>
          <Grid item md={2} xs={6}>
            <FormInput
              value={machineTableFilters?.deployed_indusoft_version}
              onChange={(value) => setMachineTableFilters({ ...machineTableFilters, deployed_indusoft_version: value })}
              label="Deployed Indusoft Version"
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <AutocompleteFilter
              label="Current DB Version"
              loading={false}
              pluralLabel="Current DB Versions"
              choices={filterOptions?.dbVersionOptions}
              setValue={(value) => setMachineTableFilters({ ...machineTableFilters, current_db_versions: value })}
              value={machineTableFilters.current_db_versions}
            />
          </Grid>
          <Grid item md={2} xs={6}>
            <FormInput
              value={machineTableFilters?.deployed_db_version}
              onChange={(value) => setMachineTableFilters({ ...machineTableFilters, deployed_db_version: value })}
              label="Deployed DB Version"
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <AutocompleteFilter
              label="Current LocalApp Version"
              loading={false}
              pluralLabel="Current LocalApp Versions"
              choices={filterOptions?.localAppOptions}
              setValue={(value) =>
                setMachineTableFilters({ ...machineTableFilters, current_local_app_versions: value })
              }
              value={machineTableFilters.current_local_app_versions}
            />
          </Grid>
          <Grid item md={2} xs={6}>
            <FormInput
              value={machineTableFilters?.deployed_local_app_version}
              onChange={(value) =>
                setMachineTableFilters({ ...machineTableFilters, deployed_local_app_version: value })
              }
              label="Deployed Local App Version"
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <SelectInput
              value={machineTableFilters.has_deployments}
              choices={[
                { label: 'All', value: 'All' },
                { label: 'Yes', value: 'Yes' },
                { label: 'No', value: 'No' },
              ]}
              onChange={(value) => setMachineTableFilters({ ...machineTableFilters, has_deployments: value })}
              label="Current Deployments"
            />
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              style={{ color: '#fff', marginTop: 19 }}
              disableElevation
              onClick={() => {
                setMachineTableFilters(defaultMachineFilterOptions);
              }}
            >
              Clear
            </Button>
          </Grid>
        </Grid>
        <br />

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3-content" id="panel3-header">
            <Typography variant="h3" component="h3">
              Advanced SQL Filter Clause
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div>
              <pre
                style={{
                  marginTop: '8px',
                  fontSize: '1rem',
                  fontWeight: 'normal',
                  whiteSpace: 'pre',
                  fontFamily: 'inherit',
                }}
              >
                FROM machine m
              </pre>
              <pre
                style={{
                  marginTop: '8px',
                  fontSize: '1rem',
                  fontWeight: 'normal',
                  whiteSpace: 'pre',
                  fontFamily: 'inherit',
                }}
              >
                JOIN company c on m.company_id = c.company_id
              </pre>
              <pre
                style={{
                  marginTop: '8px',
                  fontSize: '1rem',
                  fontWeight: 'normal',
                  whiteSpace: 'pre',
                  fontFamily: 'inherit',
                }}
              >
                LEFT JOIN machine_status ms on m.serial_number = ms.serial_number
              </pre>
            </div>
            <Grid container spacing={1} alignItems="flex-start">
              <Grid item xs={12} md={12}>
                <FormInput
                  rows={10}
                  multiline
                  value={machineTableFilters?.advanced_filter}
                  onChange={(value) => setMachineTableFilters({ ...machineTableFilters, advanced_filter: value })}
                  label="Text in box below will be added in the WHERE clause. Begin with AND"
                />
              </Grid>
            </Grid>
          </AccordionDetails>
          <AccordionActions>
            <Button
              variant="contained"
              color="primary"
              style={{ color: '#fff' }}
              disableElevation
              onClick={() => {
                fetchFunction('');
                setMachineTableFilters({ ...machineTableFilters, advanced_filter: '' });
              }}
            >
              Clear
            </Button>
            <Button
              variant="contained"
              color="primary"
              style={{ color: '#fff' }}
              disableElevation
              onClick={() => {
                handleApplyFilter();
              }}
            >
              Apply
            </Button>
          </AccordionActions>
        </Accordion>
      </Grid>
    </Grid>
  );
};

export default DeploymentMachinesListFilterBar;

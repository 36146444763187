import React, { Dispatch, SetStateAction } from 'react';
import axios from '../../utils/axios.utils';

interface SoftwareVersionContextInterface {
  fetchSoftwareVersions?: () => Promise<void>;
  createSoftwareVersion?: (formValues: any, file: any) => Promise<void>;
  deleteSoftwareVersion?: (softwareVersionId: any, onSuccess?: () => void) => Promise<void>;
  softwareVersionDeleting?: boolean;

  softwareVersion?: any[];

  softwareVersions?: any;
  softwareVersionsLoading?: boolean;

  softwareVersionsSubmitting?: boolean;
  setSoftwareVersionsSubmitting?: Dispatch<SetStateAction<boolean>>;
}

const SoftwareVersionContext = React.createContext<SoftwareVersionContextInterface>({});

const SoftwareVersionContextConsumer = SoftwareVersionContext.Consumer;
const SoftwareVersionContextProvider: React.FC = ({ children }) => {
  const [softwareVersion, setSoftwareVersion] = React.useState([]);
  const [softwareVersions, setSoftwareVersions] = React.useState([]);
  const [softwareVersionsLoading, setSoftwareVersionsLoading] = React.useState(true);
  const [softwareVersionDeleting, setSoftwareVersionDeleting] = React.useState(true);

  const [softwareVersionsSubmitting, setSoftwareVersionsSubmitting] = React.useState(false);

  const fetchSoftwareVersions = async () => {
    setSoftwareVersionsLoading(true);

    axios
      .get<string, any>(`api/admin/software_versions/`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      })
      .then((response) => {
        console.log(response);
        setSoftwareVersions(response.data.results);
        setSoftwareVersionsLoading(false);
      });
  };

  const encodeFileToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const result = reader.result as string;
        resolve(result.split(',')[1]); // Extract base64 part
      };
      reader.onerror = (error) => reject(error);
    });
  };

  const createSoftwareVersion = async (formValues: any) => {
    setSoftwareVersionsSubmitting(true);

    console.log('formValues in context:');
    console.log(formValues);

    try {
      const base64File = await encodeFileToBase64(formValues.file);

      const payload = {
        machine_software_type_id: formValues.machine_software_type_id,
        machine_type_id: formValues.machine_type_id,
        machine_software_version_name: formValues.machine_software_version_name,
        installer_s3_path: formValues.installer_s3_path,
        release_notes: formValues.release_notes,
        file: base64File, // Include the base64-encoded file
        status: formValues.status,
      };

      const response = await axios.post(`api/admin/software_versions/`, payload, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
          'Content-Type': 'application/json',
        },
      });

      console.log(response);
      setSoftwareVersionsSubmitting(false);
    } catch (error) {
      console.error('Error creating software version:', error);
      setSoftwareVersionsSubmitting(false);
    }
  };

  const deleteSoftwareVersion = async (softwareVersionId: any, onSuccess?: () => void) => {
    setSoftwareVersionDeleting(true);

    axios
    .delete<string, any>(
      `api/admin/software_versions/${softwareVersionId}/`,
      {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      },
    )
    .then((response) => {
      setSoftwareVersionDeleting(false);

      if (onSuccess) onSuccess();
    })
    .catch((error) => {
      console.error('Error creating machine deployment:', error);
      setSoftwareVersionDeleting(false);
    });
  };

  return (
    <SoftwareVersionContext.Provider
      value={{
        fetchSoftwareVersions,
        createSoftwareVersion,
        deleteSoftwareVersion,
        softwareVersionDeleting,
        softwareVersion,

        softwareVersions,
        softwareVersionsLoading,

        softwareVersionsSubmitting,
      }}
    >
      {children}
    </SoftwareVersionContext.Provider>
  );
};

export { SoftwareVersionContextProvider, SoftwareVersionContextConsumer, SoftwareVersionContext };

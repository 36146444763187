import React from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Card, CardContent, Grid, Typography } from '@mui/material';
import CompanyInformation from './CompanyInformation';
import CompanyForm from '../../companiesAdminManagement/components/CompanyForm';
import MachineInformation from './MachineInformation';
import MachineForm from './MachineForm';
import MachineMetaInformation from './MachineMetaInformation';
import MachineApiPartners from './MachineApiPartners';
import UserMachineAccess from './UserMachineAccess';
import MachineDeployments from './MachineDeployments';

interface MachineDetailsProps {
  machine: any;
}

const MachineDetails: React.FC<MachineDetailsProps> = (props) => {
  const { machine } = props;
  const history = useHistory();

  return (
    <Box>
      <Grid justifyContent="space-between" container spacing={3}>
        <Grid item xs={12} md={12}>
          <Card>
            <CardContent>
              <Grid container>
                <Grid item>
                  <Typography variant="h2" component="h2">
                    Machine Information (Machine Status: {machine?.connection_state})
                  </Typography>
                </Grid>
              </Grid>
              <br />
              <MachineForm machine={machine} />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={12}>
          <MachineApiPartners machine={machine} />
        </Grid>
        <Grid item xs={12} md={12}>
          <MachineDeployments machine={machine} />
        </Grid>
        <Grid item xs={12} md={12}>
          <MachineMetaInformation machine={machine} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default MachineDetails;

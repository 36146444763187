/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Button, Grid } from '@mui/material';
import FormInput from '../../shared/form/FormInput';
import SelectInput from '../../shared/form/SelectInput';
import defaultFilterOptions from '../constants/defaultFilterOptions';

interface SoftwareVersionsFilterBarProps {
  tableFilters?: any;
  setTableFilters?: any;
  fetchFunction?: () => void;
}

const SoftwareVersionsFilterBar: React.FC<SoftwareVersionsFilterBarProps> = (props) => {
  const { tableFilters, setTableFilters, fetchFunction } = props;

  return (
    <Grid container spacing={1} alignItems="flex-start">
      <Grid item xs={12} style={{ marginTop: 20 }}>
        <Grid container spacing={1} alignItems="flex-start">
          <Grid item md={2} xs={6}>
            <SelectInput
              value={tableFilters.machine_type}
              choices={[
                { label: 'All', value: 'All' },
                { label: 'AutoTreat', value: 'AutoTreat' },
                { label: 'AutoBulk', value: 'AutoBulk' },
              ]}
              onChange={(value) => setTableFilters({ ...tableFilters, machine_type: value })}
              label="Machine Type"
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <SelectInput
              value={tableFilters.software_type}
              choices={[
                { label: 'All', value: 'All' },
                { label: 'InduSoft', value: 'InduSoft' },
                { label: 'Database', value: 'Database' },
                { label: 'LocalApp', value: 'LocalApp' },
              ]}
              onChange={(value) => setTableFilters({ ...tableFilters, software_type: value })}
              label="Software Type"
            />
          </Grid>
          <Grid item md={2} xs={6}>
            <FormInput
              value={tableFilters?.software_version_name}
              onChange={(value) => setTableFilters({ ...tableFilters, software_version_name: value })}
              label="Software Version Name"
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SoftwareVersionsFilterBar;

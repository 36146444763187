/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Typography, Grid } from '@mui/material';
import { useHistory } from 'react-router-dom';
import SoftwareVersionForm from '../components/SoftwareVersionForm';

const SoftwareVersionsCreate: React.FunctionComponent = () => {
  const history = useHistory();

  // React.useEffect(() => {
  //   fetchAdminMachines();
  // }, []);

  return (
    <>
      <Grid justifyContent="space-between" container spacing={3}>
        <Grid item>
          <Typography
            style={{ marginBottom: 10, color: '#333', opacity: 0.6, cursor: 'pointer' }}
            onClick={() => history.push(`/admin/software_versions`)}
          >
            ← Return to Software Version List
          </Typography>
        </Grid>
      </Grid>
      <Grid justifyContent="space-between" container spacing={3}>
        <Grid item>
          <Typography variant="h1" component="h1">
            Machine Software Versions
          </Typography>
          <br />
        </Grid>
      </Grid>
      <br />
      <SoftwareVersionForm />
    </>
  );
};

export default SoftwareVersionsCreate;

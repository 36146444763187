const defaultMachineFilterOptions = {
  machine_serial: '',
  machine_name: '',
  company: '',
  system_type: 'AutoTreat',
  connection_state: 'All',
  subscription_active: 'All',
  last_online: 'All',
  current_indusoft_versions: [],
  deployed_indusoft_version: '',
  current_db_versions: [],
  deployed_db_version: '',
  current_local_app_versions: [],
  deployed_local_app_version: '',
  has_deployments: 'All',
  advanced_filter: '',
};

export default defaultMachineFilterOptions;

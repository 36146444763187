/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Button, Grid } from '@mui/material';
import FormInput from '../../shared/form/FormInput';
import SelectInput from '../../../modules/shared/form/SelectInput';
import defaultFilterOptions from '../constants/defaultFilterOptions';

interface DeploymentsFilterBarProps {
  tableFilters?: any;
  setTableFilters?: any;
  fetchFunction?: () => void;
}

const DeploymentsFilterBar: React.FC<DeploymentsFilterBarProps> = (props) => {
  const { tableFilters, setTableFilters, fetchFunction } = props;

  return (
    <Grid container spacing={1} alignItems="flex-start">
      <Grid item xs={12} style={{ marginTop: 20 }}>
        <Grid container spacing={1} alignItems="flex-start">
          <Grid item md={2} xs={6}>
            <FormInput
              value={tableFilters?.machine_serial}
              onChange={(value) => {
                setTableFilters({ ...tableFilters, machine_serial: value });
              }}
              label="Machine Serial"
            />
          </Grid>
          <Grid item md={2} xs={6}>
            <FormInput
              value={tableFilters?.machine_name}
              onChange={(value) => setTableFilters({ ...tableFilters, machine_name: value })}
              label="Machine Name"
            />
          </Grid>
          <Grid item md={2} xs={6}>
            <FormInput
              value={tableFilters?.company}
              onChange={(value) => setTableFilters({ ...tableFilters, company: value })}
              label="Company"
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <SelectInput
              value={tableFilters.system_version}
              choices={[
                { label: 'All', value: 'All' },
                { label: 'V5.0 AT', value: 'V5.0 AT' },
                { label: 'AutoBulk', value: 'AutoBulk' },
              ]}
              onChange={(value) => setTableFilters({ ...tableFilters, system_version: value })}
              label="System Version"
            />
          </Grid>

          <Grid item xs={12} md={2}>
            <SelectInput
              value={tableFilters.software_type}
              choices={[
                { label: 'All', value: 'All' },
                { label: 'Indusoft', value: 'Indusoft' },
                { label: 'Database', value: 'Database' },
                { label: 'LocalApp', value: 'LocalApp' },
              ]}
              onChange={(value) => setTableFilters({ ...tableFilters, software_type: value })}
              label="Software Type"
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <SelectInput
              value={tableFilters.completed}
              choices={[
                { label: 'All', value: 'All' },
                { label: 'Completed', value: 'True' },
                { label: 'Pending', value: 'False' },
              ]}
              onChange={(value) => setTableFilters({ ...tableFilters, completed: value })}
              label="Completed"
            />
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              style={{ color: '#fff', marginTop: 19 }}
              disableElevation
              onClick={() => {
                setTableFilters(defaultFilterOptions);
              }}
            >
              Clear
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              style={{ color: '#fff', marginTop: 19 }}
              disableElevation
              onClick={() => {
                fetchFunction();
              }}
            >
              Refresh
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DeploymentsFilterBar;

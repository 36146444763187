import React from 'react';
import { Box, ListItemIcon, MenuItem, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import CustomMaterialMenu from '../../dataTable/rowMenu/CustomMaterialMenu';
import ConfirmCancelModal from '../../shared/modal/ConfirmCancelModal';
import { SoftwareVersionContext } from '../contexts/SoftwareVersionsContext';

interface SoftwareVersionRowMenuProps {
  softwareVersion: any;
}

const useStyles = makeStyles(() => ({
  listItemIcon: {
    minWidth: '24px',
  },
}));

const SoftwareVersionRowMenu: React.FC<SoftwareVersionRowMenuProps> = (props: SoftwareVersionRowMenuProps) => {
  const { softwareVersion } = props;
  const classes = useStyles();
  const [modalOpen, setModalOpen] = React.useState<boolean>(false);

  const { deleteSoftwareVersion, fetchSoftwareVersions } = React.useContext(SoftwareVersionContext);

  const handleEmail = async () => {
    deleteSoftwareVersion((softwareVersion.machine_software_version_id), () => fetchSoftwareVersions())
    setModalOpen(false)
  };

  return (
    <Box>
      <ConfirmCancelModal
        isOpen={modalOpen}
        onCancel={() => setModalOpen(false)}
        onConfirm={handleEmail}
        closeOnConfirm
        title="Delete SoftwareVersion?"
        hasFooterBar
        confirmLabel="Delete SoftwareVersion"
      >
        <div style={{ margin: '10px 20px' }}>
          <Typography style={{ marginLeft: -15 }} variant="h2" component="h2">
            Are you sure you want to delete this software version?
          </Typography>
        </div>
      </ConfirmCancelModal>
      <CustomMaterialMenu size="medium">
        <div />
        <MenuItem onClick={() => setModalOpen(true)}>
          <ListItemIcon className={classes.listItemIcon}>
            <DeleteOutlinedIcon fontSize="small" color="secondary" />
          </ListItemIcon>
          <Typography variant="inherit">Delete Software Version</Typography>
        </MenuItem>
      </CustomMaterialMenu>
    </Box>
  );
};

export default SoftwareVersionRowMenu;

/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Card, CardContent, Typography, Grid } from '@mui/material';
import DataTable from '../../dataTable/DataTable/DataTable';
import { MachinesContext } from '../../machinesAdminManagement/contexts/MachinesContext';
import DeploymentMachinesListFilterBar from '../components/DeploymentMachinesListFilterBar';
import { MachineDeploymentContext } from '../contexts/DeploymentsContext';
import getColumns from '../constants/machineListColumns';
import { IDataTableColumn } from '../../dataTable/DataTable/model';
import defaultMachineFilterOptions from '../constants/defaultMachineFilterOptions';
import DeploymentDropdown from '../components/DeploymentDropDowns';

const DeploymentsCreate: React.FunctionComponent = () => {
  const history = useHistory();

  const { fetchAdminMachines, adminMachines, machinesLoading } = React.useContext(MachinesContext);
  const { machineDeploymentOptions, fetchMachineDeploymentOptions, createMachineDelpoyment, fetchMachineDeployments } =
    React.useContext(MachineDeploymentContext);

  const [machineTableFilters, setMachineTableFilters] = React.useState(defaultMachineFilterOptions);
  const [selectedVersions, setSelectedVersions] = React.useState({
    indusoftVersion: '',
    dbVersion: '',
    localAppVersion: '',
  });
  const [selectedMachines, setSelectedMachines] = React.useState([]);
  const [machineFilterOptions, setMachineFilterOptions] = React.useState({
    indusoftVersions: [],
    dbVersionOptions: [],
    localAppOptions: [],
  });
  const [showOnlySelectedMachines, setShowOnlySelectedMachines] = React.useState<boolean>(false);
  const [filteredMachineDeploymentOptions, setFilteredMachineDeploymentOptions] = React.useState({});

  const [columns, setColumns] = React.useState<IDataTableColumn<any>[] | undefined>(undefined);

  React.useEffect(() => {
    fetchMachineDeploymentOptions();
    fetchAdminMachines();
    fetchMachineDeployments();
  }, []);

  React.useEffect(() => {
      const filterOptions = {
        indusoftVersions: new Set(),
        dbVersionOptions: new Set(),
        localAppOptions: new Set(),
      };
  
      adminMachines.forEach((machine) => {
        if (machine.system_type === machineTableFilters?.system_type) {
          if (machine.indusoft_project) {
            filterOptions.indusoftVersions.add(machine.indusoft_project);
          }
          if (machine.db_version) {
            filterOptions.dbVersionOptions.add(machine.db_version);
          }
          if (machine.ksi_local_app_version) {
            filterOptions.localAppOptions.add(machine.ksi_local_app_version);
          }
        }
      });
      
      setMachineFilterOptions({
        indusoftVersions: Array.from(filterOptions.indusoftVersions),
        dbVersionOptions: Array.from(filterOptions.dbVersionOptions),
        localAppOptions: Array.from(filterOptions.localAppOptions),
      });
  }, [adminMachines, machineTableFilters.system_type]);

  React.useEffect(() => {
    if (machineDeploymentOptions && machineDeploymentOptions.length > 0) {
      const emptyOption = { label: '', value: '' };
      setFilteredMachineDeploymentOptions({
        ...filteredMachineDeploymentOptions,
        db_version_options: [
          emptyOption,
          ...machineDeploymentOptions
            .filter((choice) => choice.machine_software_type === 'Database' && choice.machine_type === machineTableFilters.system_type)
            .map((choice) => ({
              label: choice.machine_software_version_name,
              value: choice.machine_software_version_id,
            })),
        ],
        indusoft_version_options: [
          emptyOption,
          ...machineDeploymentOptions
            .filter((choice) => choice.machine_software_type === 'InduSoft' && choice.machine_type === machineTableFilters.system_type)
            .map((choice) => ({
              label: choice.machine_software_version_name,
              value: choice.machine_software_version_id,
            })),
        ],
        ksi_local_app_version_options: [
          emptyOption,
          ...machineDeploymentOptions
            .filter((choice) => choice.machine_software_type === 'LocalApp' && choice.machine_type === machineTableFilters.system_type)
            .map((choice) => ({
              label: choice.machine_software_version_name,
              value: choice.machine_software_version_id,
            })),
        ],
      });
    }
  }, [machineDeploymentOptions, machineTableFilters.system_type]);

  React.useEffect(() => {
    if (machineDeploymentOptions && adminMachines) {
      let tempColumns = getColumns();
      setColumns(tempColumns);
    }
  }, [machineDeploymentOptions, adminMachines]);

  const filterMachines = () => {
    let filteredMachines = adminMachines;
    if (
      machineTableFilters.machine_serial ||
      machineTableFilters.machine_name ||
      machineTableFilters.company ||
      machineTableFilters.system_type !== 'All' ||
      machineTableFilters.connection_state !== 'All' ||
      machineTableFilters.subscription_active !== 'All' ||
      machineTableFilters.last_online !== 'All' ||
      machineTableFilters.current_indusoft_versions ||
      machineTableFilters.deployed_indusoft_version ||
      machineTableFilters.current_db_versions ||
      machineTableFilters.has_deployments !== 'All'
    ) {
      if (machineTableFilters.machine_serial) {
        filteredMachines = filteredMachines.filter((machine) =>
          machine.serial_number?.toLowerCase().includes(machineTableFilters.machine_serial.toLowerCase()),
        );
      }

      if (machineTableFilters.machine_name) {
        filteredMachines = filteredMachines.filter((machine) =>
          machine.machine_name_with_serial?.toLowerCase().includes(machineTableFilters.machine_name.toLowerCase()),
        );
      }

      if (machineTableFilters.company) {
        filteredMachines = filteredMachines.filter((machine) =>
          machine.company_name?.toLowerCase().includes(machineTableFilters.company.toLowerCase()),
        );
      }

      if (machineTableFilters.system_type !== 'All') {
        filteredMachines = filteredMachines.filter((machine) =>
          machine.system_type?.toLowerCase().includes(machineTableFilters.system_type.toLowerCase()),
        );
      }

      if (machineTableFilters.connection_state !== 'All') {
        filteredMachines = filteredMachines.filter((machine) =>
          machine.connection_state?.toLowerCase().includes(machineTableFilters.connection_state.toLowerCase()),
        );
      }

      // Note some dev machines have null here instead of string true or false. don't think prod db has this issue
      if (machineTableFilters.subscription_active !== 'All') {
        filteredMachines = filteredMachines.filter(
          (machine) =>
            String(machine.subscription_active).toLowerCase() === machineTableFilters.subscription_active.toLowerCase(),
        );
      }

      if (machineTableFilters.last_online !== 'All') {
        const minutes = parseInt(machineTableFilters.last_online, 10);
        const now = new Date(); // Current local time
        const nowUTC = new Date(now.getTime() + now.getTimezoneOffset() * 60000); // Convert to UTC time

        filteredMachines = filteredMachines.filter((machine) => {
          if (!machine.last_online_date) return false;

          const lastOnlineDate = new Date(machine.last_online_date);
          if (isNaN(lastOnlineDate.getTime())) return false; // Handle invalid date

          const differenceInMinutes = (nowUTC.getTime() - lastOnlineDate.getTime()) / (1000 * 60);
          return differenceInMinutes <= minutes;
        });
      }

      if (machineTableFilters.current_indusoft_versions && machineTableFilters.current_indusoft_versions.length > 0) {
        filteredMachines = filteredMachines.filter((machine) =>
          machineTableFilters.current_indusoft_versions.includes(machine.indusoft_project),
        );
      }

      if (machineTableFilters.deployed_indusoft_version) {
        const filterVersion = machineTableFilters.deployed_indusoft_version.toLowerCase();
        filteredMachines = filteredMachines.filter(
          (machine) =>
            Array.isArray(machine.machine_deployments) &&
            machine.machine_deployments.some((deployment) =>
              deployment.machine_software_version_name.toLowerCase().includes(filterVersion),
            ),
        );
      }

      if (machineTableFilters.current_db_versions && machineTableFilters.current_db_versions.length > 0) {
        filteredMachines = filteredMachines.filter((machine) =>
          machineTableFilters.current_db_versions.includes(machine.db_version),
        );
      }

      if (machineTableFilters.deployed_db_version) {
        const filterVersion = machineTableFilters.deployed_db_version.toLowerCase();
        filteredMachines = filteredMachines.filter(
          (machine) =>
            Array.isArray(machine.machine_deployments) &&
            machine.machine_deployments.some((deployment) =>
              deployment.machine_software_version_name.toLowerCase().includes(filterVersion),
            ),
        );
      }

      if (machineTableFilters.current_local_app_versions && machineTableFilters.current_local_app_versions.length > 0) {
        filteredMachines = filteredMachines.filter((machine) =>
          machineTableFilters.current_local_app_versions.includes(machine.ksi_local_app_version),
        );
      }

      if (machineTableFilters.deployed_local_app_version) {
        const filterVersion = machineTableFilters.deployed_local_app_version.toLowerCase();
        filteredMachines = filteredMachines.filter(
          (machine) =>
            Array.isArray(machine.machine_deployments) &&
            machine.machine_deployments.some((deployment) =>
              deployment.machine_software_version_name.toLowerCase().includes(filterVersion),
            ),
        );
      }

      if (machineTableFilters.has_deployments !== 'All') {
        if (machineTableFilters.has_deployments === 'Yes') {
          filteredMachines = filteredMachines.filter(
            (machine) => machine.machine_deployments && machine.machine_deployments.length > 0,
          );
        }

        if (machineTableFilters.has_deployments === 'No') {
          filteredMachines = filteredMachines.filter(
            (machine) => !machine.machine_deployments || machine.machine_deployments.length === 0,
          );
        }
      }

      return filteredMachines;
    }

    return filteredMachines;

  };

  const handleSelectedRowsChange = (state) => {
    // Convert selectedRows to a normal array using the spread operator
    const selectedMachinesArray = [...state.selectedRows];
    setSelectedMachines(selectedMachinesArray);
  };

  const handleShowOnlySelectedMachines = (state) => {
    setShowOnlySelectedMachines(!showOnlySelectedMachines);
  };

  const handleSendMachines = () => {
    let tempDeployments = [];

    // Convert `selectedVersions` object into an array of version objects
    const versionArray = Object.entries(selectedVersions)
      .filter(([key, value]) => value) // Remove empty or null versions
      .map(([key, value]) => ({ id: value, type: key })); // Create objects with `id` and `type`

    selectedMachines.forEach((machine) => {
      versionArray.forEach((version) => {
        tempDeployments.push({
          machine_id: machine.machine_id,
          software_version_id: version.id,
        });
      });
    });
  
    createMachineDelpoyment(tempDeployments, () => {
      fetchMachineDeployments();
      fetchAdminMachines();
    });
  };

  return (
    <>
      <Grid justifyContent="space-between" container spacing={3}>
        <Grid item>
          <Typography
            style={{ marginBottom: 10, color: '#333', opacity: 0.6, cursor: 'pointer' }}
            onClick={() => history.push(`/admin/machine_deployments`)}
          >
            ← Return to Deployment List
          </Typography>
        </Grid>
      </Grid>
      <Grid justifyContent="space-between" container spacing={3}>
        <Grid item>
          <Typography variant="h1" component="h1">
            Machine Deployments
          </Typography>
          <br />
        </Grid>
      </Grid>
      <DeploymentMachinesListFilterBar
        machineTableFilters={machineTableFilters}
        setMachineTableFilters={setMachineTableFilters}
        filterOptions={machineFilterOptions}
        fetchFunction={fetchAdminMachines}
      />
      <br />
      <Card>
        <CardContent>
          <DeploymentDropdown
            filterOptions={filteredMachineDeploymentOptions}
            selectedVersions={selectedVersions}
            setSelectedVersions={setSelectedVersions}
            buttonDisabled={!selectedMachines.length || !selectedVersions.indusoftVersion && !selectedVersions.dbVersion && !selectedVersions.localAppVersion}
            togglShowOnlySelectedMachines={handleShowOnlySelectedMachines}
            applyFunction={handleSendMachines}
          />
          <br />
          <DataTable
            noHeader
            columns={columns}
            data={!showOnlySelectedMachines ? filterMachines(): selectedMachines}
            defaultSortField="order_complete_date_and_time_utc"
            defaultSortAsc={false}
            striped
            selectableRows
            onSelectedRowsChange={handleSelectedRowsChange}
            selectedRows={selectedMachines}
            highlightOnHover
            pointerOnHover
            pagination
            paginationPerPage={25}
            paginationRowsPerPageOptions={[10, 25, 50]}
            progressPending={machinesLoading}
          />
        </CardContent>
      </Card>
    </>
  );
};

export default DeploymentsCreate;

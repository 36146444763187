const defaultFilterOptions = {
  machine_serial: '',
  machine_name: '',
  company: '',
  system_version: 'All',
  software_type: 'All',
  completed: 'All',
};

export default defaultFilterOptions;

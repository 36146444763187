import React from 'react';
import { format } from 'date-fns';
import { IDataTableColumn } from '../../dataTable/DataTable/model';
import AdminMachineRowMenu from '../components/MachineRowMenu';

const columns: IDataTableColumn<any>[] = [
  {
    name: 'Machine Name',
    selector: 'machine_name',
    sortable: true,
  },
  {
    name: 'Machine Serial',
    selector: 'serial_number',
    sortable: true,
    sortFunction: (a: Record<string, string>, b: Record<string, string>): unknown => {
      const valA = parseFloat(a.serial_number) || 0;
      const valB = parseFloat(b.serial_number) || 0;
      return valA - valB;
    },
  },
  {
    name: 'Company',
    selector: 'company_name',
    sortable: true,
  },
  {
    name: 'Online Status',
    selector: 'connection_state',
    sortable: true,
    grow: 0.9,
    cell: ({ connection_state }): React.ReactNode => (
      <div
        style={{
          fontWeight: 700,
          textTransform: 'uppercase',
          fontSize: 12,
          color: connection_state === 'online' ? 'green' : 'red',
        }}
      >
        {connection_state}
      </div>
    ),
  },
  {
    name: 'Last Online',
    selector: 'last_online_date',
    grow: 1.2,
    sortable: true,
    cell: (row): unknown => <p>{row?.last_online_date === 'Never' ? 'Never' : row?.last_online_date}</p>,
  },
  {
    name: 'System Version',
    selector: 'software_version',
    sortable: true,
  },
  {
    name: 'InduSoft Project',
    selector: 'indusoft_project',
    sortable: true,
  },
  {
    name: 'DB Version',
    selector: 'db_version',
    sortable: true,
  },
  {
    name: 'Local Service Version',
    selector: 'ksi_local_app_version',
    sortable: true,
  },
  {
    name: 'PLC Version',
    selector: 'pac_version',
    sortable: true,
  },
  {
    name: 'Subscription',
    selector: 'subscription_active',
    sortable: true,
    cell: ({ subscription_active }): React.ReactNode => (
      <div
        style={{
          fontWeight: 700,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          maxWidth: '100%',
          textTransform: 'uppercase',
          fontSize: 12,
          color: subscription_active ? 'green' : 'red',
        }}
      >
        {subscription_active ? 'ACTIVE' : 'INACTIVE'}
      </div>
    ),
  },
  {
    name: 'Expiration',
    selector: 'subscription_expiration_date',
    sortable: true,
  },
  {
    name: 'Actions',
    cell: (row) => <AdminMachineRowMenu machine={row} isAdmin />,
    allowOverflow: true,
    button: true,
    selector: 'actions',
  },
];

export default columns;

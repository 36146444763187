import React from 'react';
import { format } from 'date-fns';
import { IDataTableColumn } from '../../dataTable/DataTable/model';

interface ColumnOptions {
  indusoft_version_options: { label: string; value: string }[];
  db_version_options: { label: string; value: string }[];
  ksi_local_app_version_options: { label: string; value: string }[];
}

const getColumns = (
): IDataTableColumn[] => [
  {
    name: 'Machine Name',
    selector: 'machine_name_with_serial',
    sortable: true,
    grow: 2,
  },
  {
    name: 'Company',
    selector: 'company_name',
    sortable: true,
    grow: 2,
  },
  {
    name: 'System Type',
    selector: 'system_type',
    sortable: true,
    grow: 1.5,
  },
  {
    name: 'System Version',
    selector: 'software_version',
    grow: 1.2,
    sortable: true,
  },
  {
    name: 'Online Status',
    selector: 'connection_state',
    sortable: true,
    cell: ({ connection_state }): React.ReactNode => (
      <div
        style={{
          fontWeight: 700,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          maxWidth: '100%',
          textTransform: 'uppercase',
          fontSize: 12,
          color: (connection_state = 'online') ? 'green' : 'red',
        }}
      >
        {connection_state ? 'Online' : 'Offline'}
      </div>
    ),
  },
  {
    name: 'Subscription',
    selector: 'subscription_active',
    sortable: true,
    cell: ({ subscription_active }): React.ReactNode => (
      <div
        style={{
          fontWeight: 700,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          maxWidth: '100%',
          textTransform: 'uppercase',
          fontSize: 12,
          color: subscription_active ? 'green' : 'red',
        }}
      >
        {subscription_active ? 'ACTIVE' : 'INACTIVE'}
      </div>
    ),
  },

  {
    name: 'Last Online',
    selector: 'last_online_date',
    sortable: true,
    grow: 1.5,
    sortFunction: (rowA, rowB) =>
      new Date(rowA?.last_online_date.replace(/(\.\d{3})\d*/, '$1')).getTime() -
      new Date(rowB?.last_online_date.replace(/(\.\d{3})\d*/, '$1')).getTime(),
    cell: (row: any) => {
      let formattedDate = '';
  
      if (row?.last_online_date) {
        try {
          // Truncate the fractional seconds to three digits for compatibility
          const validDateString = row.last_online_date.replace(/(\.\d{3})\d*/, '$1');
          const date = new Date(validDateString);
  
          if (!isNaN(date.getTime())) {
            formattedDate = format(date, 'MM/dd/yyyy hh:mm aaa');
          } else {
            console.error('Invalid date:', row.last_online_date);
          }
        } catch (error) {
          console.error('Error formatting date:', error);
        }
      }
  
      return <p>{formattedDate}</p>;
    },
  },
  {
    name: 'Current InduSoft Version',
    selector: 'indusoft_project',
    sortable: true,
    grow: 1.2,
  },
  {
    name: 'Deployment InduSoft Version',
    selector: 'machine_software_deployments',
    sortable: true,
    cell: ({ machine_deployments }): React.ReactNode => {
      // Check if machine_deployments is a valid array
      if (!Array.isArray(machine_deployments)) {
        return (
          <div
            style={{
              fontWeight: 700,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: '100%',
              textTransform: 'uppercase',
              fontSize: 12,
              color: 'black', // Default color when blank
            }}
          >
            {/* Display nothing if machine_deployments is not an array */}
          </div>
        );
      }

      const induSoftDeployment = machine_deployments.find(
        (deployment: any) => deployment.machine_software_type_id === 2
      );

      // Get the version name if exists, otherwise blank
      const versionName = induSoftDeployment
        ? induSoftDeployment.machine_software_version_name
        : '';

      return (
        <div
          style={{
            fontWeight: 700,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: '100%',
            textTransform: 'uppercase',
            fontSize: 12
          }}
        >
          {versionName}
        </div>
      );
    },
    grow: 1.2,
  },
  {
    name: 'Current Database Version',
    selector: 'db_version',
    sortable: true,
    grow: 1.1,
  },
  {
    name: 'Deployment Database Version',
    selector: 'machine_software_deployments',
    sortable: true,
    cell: ({ machine_deployments }): React.ReactNode => {
      // Check if machine_deployments is a valid array
      if (!Array.isArray(machine_deployments)) {
        return (
          <div
            style={{
              fontWeight: 700,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: '100%',
              textTransform: 'uppercase',
              fontSize: 12,
              color: 'black', // Default color when blank
            }}
          >
            {/* Display nothing if machine_deployments is not an array */}
          </div>
        );
      }

      const induSoftDeployment = machine_deployments.find(
        (deployment: any) => deployment.machine_software_type_id === 1
      );

      // Get the version name if exists, otherwise blank
      const versionName = induSoftDeployment
        ? induSoftDeployment.machine_software_version_name
        : '';

      return (
        <div
          style={{
            fontWeight: 700,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: '100%',
            textTransform: 'uppercase',
            fontSize: 12
          }}
        >
          {versionName}
        </div>
      );
    },
    grow: 1.1,
  },
  {
    name: 'Current LocalApp Version',
    selector: 'ksi_local_app_version',
    sortable: true,
    grow: 1.2,
  },
  {
    name: 'Deployment Database Version',
    selector: 'machine_software_deployments',
    sortable: true,
    cell: ({ machine_deployments }): React.ReactNode => {
      // Check if machine_deployments is a valid array
      if (!Array.isArray(machine_deployments)) {
        return (
          <div
            style={{
              fontWeight: 700,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: '100%',
              textTransform: 'uppercase',
              fontSize: 12,
              color: 'black', // Default color when blank
            }}
          >
            {/* Display nothing if machine_deployments is not an array */}
          </div>
        );
      }

      const induSoftDeployment = machine_deployments.find(
        (deployment: any) => deployment.machine_software_type_id === 3
      );

      // Get the version name if exists, otherwise blank
      const versionName = induSoftDeployment
        ? induSoftDeployment.machine_software_version_name
        : '';

      return (
        <div
          style={{
            fontWeight: 700,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: '100%',
            textTransform: 'uppercase',
            fontSize: 12
          }}
        >
          {versionName}
        </div>
      );
    },
    grow: 1.1,
  },
];

export default getColumns;

import React from 'react';
import { Button, Grid, Checkbox, FormControlLabel } from '@mui/material';
import { SelectOption } from '../../shared/model';
import SelectInput from '../../shared/form/SelectInput';

export interface FilterProps {
  label: string;
  pluralLabel: string;
  selector: string;
  width: any;
  type: string;
  optionSelector: string;
  options?: SelectOption[];
}

interface DeploymentDropdownProps {
  filterOptions?: any;
  selectedVersions?: any;
  setSelectedVersions?: any;
  buttonDisabled?: any;
  togglShowOnlySelectedMachines?: any;
  applyFunction?: () => void;
}

const DeploymentDropdown: React.FC<DeploymentDropdownProps> = (props) => {
  const { filterOptions, selectedVersions, setSelectedVersions, buttonDisabled, togglShowOnlySelectedMachines,  applyFunction } = props;

  return (
    <>
      <Grid container spacing={1} alignItems="center">
        <Grid item xs={12} md={2}>
          <SelectInput
            value={selectedVersions.indusoftVersion}
            onChange={(value) => setSelectedVersions({ ...selectedVersions, indusoftVersion: value })}
            choices={filterOptions?.indusoft_version_options}
            label="Indusoft Deployment"
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <SelectInput
            value={selectedVersions.dbVersion}
            onChange={(value) => setSelectedVersions({ ...selectedVersions, dbVersion: value })}
            choices={filterOptions?.db_version_options}
            label="Database Deployment"
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <SelectInput
            value={selectedVersions.localAppVersion}
            onChange={(value) => setSelectedVersions({ ...selectedVersions, localAppVersion: value })}
            choices={filterOptions?.ksi_local_app_version_options}
            label="LocalApp Deployment"
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <Button
            variant="contained"
            color="primary"
            style={{ color: '#fff', marginTop: '16px' }}
            disableElevation
            disabled={buttonDisabled}
            onClick={() => {
              applyFunction();
            }}
          >
            Apply
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={12} style={{ marginTop: '16px' }}>
        <FormControlLabel
          control={
            <Checkbox
              onChange={(event) => {togglShowOnlySelectedMachines()}}
            />
          }
          label="Show only selected machines"
        />
      </Grid>
    </>
  );
};

export default DeploymentDropdown;
